import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { notification } from 'antd';

import { firebase } from '@app/services/firebase';
import Auth from '@app/components/auth';
import { AuthFormValueType } from '@app/components/auth/auth';
import PublicLayout from '@app/components/layouts/public-layout';
import { useAuth } from '@app/context/useAuth';
import { queryString } from '@app/utils';
import { FirebaseErrorResolver, FirebaseErrorResolverHint, UserType } from '@app/types';

export default function LogIn(): JSX.Element {
  const { signIn, send2FACode, resolveSignIn } = useAuth();
  const router = useRouter();
  const [showVerify, setShowVerify] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const [verifyMethodHints, setVerifyMethodHints] = useState<FirebaseErrorResolverHint[]>([]);
  const captchaResolver = useRef<FirebaseErrorResolver | null>(null);
  const verificationId = useRef<string | null>(null);
  const [loading, setLoading] = useState(false);
  const onLoggedInRequest = (data: Omit<AuthFormValueType, 'phone' | 'verifycode'>) => {
    setLoading(true);
    notification.destroy();
    signIn(data.username, data.password)
      .then((user) => {
        const currentUser = user as UserType;
        if (currentUser) {
          if (currentUser.roles.accountant) {
            router.push('/reports');
          } else if (currentUser.roles.ticketAgent) {
            router.push('/float-in');
          } else {
            router.push('/tickets/book-tickets');
          }
        }
      })
      .catch((error: firebase.FirebaseError & { resolver: FirebaseErrorResolver }) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          const { resolver } = error;
          const phoneHints = resolver.hints.filter(
            (item) => item.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
          );
          if (phoneHints) {
            captchaResolver.current = resolver;
            setVerifyMethodHints(phoneHints);
            setShowVerify(true);
          } else {
            notification.error({
              message: 'Phone authentication not found.',
              description: 'Please contact system administrator to resolve this issue.',
              duration: 5,
            });
          }
        } else {
          notification.error({
            message: 'Login failed.',
            description: error.message,
            duration: 5,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRecaptchaVerified = (
    multiFactorHintUid: string,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
  ) => {
    if (captchaResolver.current) {
      setLoading(true);
      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        multiFactorHint: captchaResolver.current.hints.find(
          (hint) => hint.uid === multiFactorHintUid,
        ),
        session: captchaResolver.current.session,
      };
      send2FACode(phoneInfoOptions, recaptchaVerifier)
        .then((id) => {
          verificationId.current = id;
          setShow2FA(true);
          setShowVerify(false);
        })
        .catch((error: firebase.FirebaseError) => {
          notification.error({
            message: error.message,
            duration: 5,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCodeVerify = (verifyCode: string) => {
    if (verificationId.current && captchaResolver.current) {
      setLoading(true);
      resolveSignIn(verifyCode, verificationId.current, captchaResolver.current)
        .then((user) => {
          if (user) {
            router.push('/tickets/book-tickets');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const { idleTimeout } = queryString();
    if (idleTimeout) {
      notification.destroy();
      notification.error({
        message: "You've been logged out due to inactivity.",
        duration: 0,
      });
    }
  }, []);

  return (
    <PublicLayout pageTitle="login">
      <Auth
        showLogin={!showVerify && !show2FA}
        showMultiFactorCodeInput={show2FA}
        showVerifyMethods={showVerify}
        verifyMethods={verifyMethodHints}
        onRecaptchaVerified={onRecaptchaVerified}
        onCodeVerify={onCodeVerify}
        onLoggedInRequest={(data) => {
          onLoggedInRequest(data);
        }}
        loading={loading}
      />
    </PublicLayout>
  );
}
